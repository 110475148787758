import React, { useContext, useState } from "react";
import { GlobalContext } from "@Context/StoreContext";
import { useHistory } from "react-router-dom";
import { setSearchInput } from "@Context/actions/search/setSearchInput";
import { Autocomplete } from "../../components/componentsList";

const Search = () => {
  const { searchState, searchDispatch } = useContext(GlobalContext);
  const history = useHistory();
  
  const [searchInputGiven, setSearchInputGiven] = useState(false);
  
  function activateSearch(e) {
    process.env.Module === "dev"
      ? history.push("/membersearch/results")
      : (window.location.href =
          "/ledenzoek-resultaat");
  }

  function storeInput(value, inputCategory) {
    setSearchInput(searchState.input, searchDispatch, value, inputCategory);
    value.Name.length > 0
      ? setSearchInputGiven(true)
      : setSearchInputGiven(false);
  }

  return (
    <section className="search-box">
      <div className="field company">
        <div className="autocomplete-container input">
          <Autocomplete
            placeholder="Zoek op opdracht of bedrijf"
            filterID="company"
            suggestions={searchState.suggestions.data.companies}
            storeInput={storeInput}
            defaultValueForInput=""
            useGeo={false}
            tooltipIcon=""
          />
        </div>
      </div>

      <div className="field location">
        <div className="autocomplete-container input">
          <Autocomplete
            placeholder="Vul uw plaats of postcode in"
            filterID="place"
            suggestions={searchState.suggestions.data.places}
            storeInput={storeInput}
            defaultValueForInput=""
            useGeo={true}
            tooltipIcon="Bepaal mijn coördinaten"
          />
        </div>
      </div>

      {searchInputGiven && (
        <a className="btn" onClick={activateSearch}>
          Vind een vakman
        </a>
      )}
      {!searchInputGiven && <a className="btn disabled">Vind een vakman</a>}
    </section>
  );
};

export { Search };
