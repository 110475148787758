import React, { useState, useContext } from "react";
import { CompanyCard } from "./CompanyCard";

const ResultsList = ({ list, qualities }) => {
  const absoluteDomain = 'https://leden.technieknederland.nl/';
  const [resultListingOptions, setResultListingOptions] = useState({
    showPopup: false,
    currentCertificates: [],
    doneShuffle: false,
  });

  function togglePopup(certificates) {
    setResultListingOptions({
      ...resultListingOptions,
      showPopup: !resultListingOptions.showPopup,
      currentCertificates: certificates,
    });
  }

  function checkWebsite(company) {
    const url =
      company.Site && company.Site.length > 0
        ? company.Site
        : company.url_1 && company.url_1.length > 0
        ? company.url_1
        : "";
    return url.length > 0 ? `https://${url}` : "";
  }

  function getCertificates(regelingen) {
    return qualities.filter((element) => regelingen.includes(element.Id));
  }

  function toggleMoreInfo(e) {
    e.currentTarget.parentNode.classList.toggle("more-info-active");
  }

  return (
    <>
      {list.map((result, index) => (
        <CompanyCard
          key={index}
          companyDetail={result}
          checkWebsite={checkWebsite}
          toggleMoreInfo={toggleMoreInfo}
          togglePopup={togglePopup}
          getCertificates={getCertificates}
        />
      ))}

      <div
        className={
          resultListingOptions.showPopup ? "show popup-info" : "hide popup-info"
        }
      >
        <div
          className="close"
          data-remove-if-empty="ignore"
          onClick={(e) => togglePopup(e, [])}
        />
        <h3>Certificaten en regelingen</h3>
        <p>
          Certificaten en regelingen zijn een teken van bewezen vakmanschap.
          Heeft dit bedrijf de beste papieren om uw opdracht uit te voeren?
        </p>
        <a
          className="btn"
          href="https://leden.technieknederland.nl/kwaliteit/certificaten-en-erkenningen"
        >
          Lees meer over certificering
        </a>
        <ul>
          {resultListingOptions.currentCertificates.length > 0 &&
            resultListingOptions.currentCertificates.map(
              (certificate, index) => (
                <li key={(certificate, index)}>
                  <img
                    src={`${absoluteDomain}${certificate.Logo}`}
                    title={certificate.Name}
                    width="32"
                  />
                  <span className="certificate-name">{certificate.Name}</span>
                </li>
              )
            )}
        </ul>
      </div>
    </>
  );
};

export { ResultsList };

/*

        */
