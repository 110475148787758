import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "@Context/StoreContext";
import { extendedSearchRoot } from "@Constants";
import { setSearchInput } from "@Context/actions/search/setSearchInput";
import { Autocomplete } from "../../components/componentsList";

const ExtendedSearch = () => {
  const { searchState, searchDispatch } = useContext(GlobalContext);
  const history = useHistory();

  const [extendedOption, setExtendedOption] = useState({
    mobileOpen: false,
    searchButton: checkForInput(),
    list: [],
    qualitySchemesUsed: [],
    sessionTaken: false,
    doneLoading: false,
    qualitySelected: searchState.input.data.quality
      ? searchState.input.data.quality.Id
      : 0,
  });

  function checkForInput() {
    return searchState.input.data.place.length ||
      searchState.input.data.company.Name ||
      searchState.input.data.quality.Name
      ? true
      : false;
  }

  useEffect(() => {
    if (!extendedOption.doneLoading && searchState.qualities.doneLoading) {
      setExtendedOption({
        ...extendedOption,
        qualitySchemesUsed: getQualityNames(),
        doneLoading: true,
      });
    }
  }, [searchState.qualities.doneLoading]);

  function getQualityNames() {
    return searchState.filterData.data.Kwaliteitsregelingen.filter(
      (quality) => searchState.qualities.data.indexOf(quality.Id) > -1
    );
  }

  function checkForZipcode() {
    const place = searchState.input.data.place.length
      ? searchState.input.data.place
      : "";
    const placeInput = place
      .replace(/\./g, "")
      .replace(/ /g, "")
      .substring(0, 4);
    const placeNumber = placeInput.match("^[0-9]+$") || [];

    return placeNumber.length > 0 ? true : false;
  }

  const activateSearch = (e) => {
    process.env.Module === "dev"
      ? history.push("/membersearch/results")
      : (window.location.href =
          "/ledenzoek-resultaat");
  };

  function storeInput(value, inputCategory) {
    setSearchInput(searchState.input, searchDispatch, value, inputCategory);
    value.Name.length > 0
      ? setExtendedOption({ ...extendedOption, searchButton: true })
      : setExtendedOption({ ...extendedOption, searchButton: false });
  }

  function selectDistance(e) {
    setSearchInput(
      searchState.input,
      searchDispatch,
      parseInt(e.target.value),
      "distance"
    );
  }

  function selectKwaliteitsregeling(e) {
    const selectedId = parseInt(e.target.value);
    let qualitySelected = {};
    if (selectedId !== 0) {
      qualitySelected = searchState.filterData.data.Kwaliteitsregelingen.filter(
        (quality) => selectedId === quality.Id
      ).map((q) => {
        return { Name: q.Name, Id: q.Id };
      })[0];
    }

    setSearchInput(
      searchState.input,
      searchDispatch,
      qualitySelected,
      "quality"
    );

    setExtendedOption({ ...extendedOption, searchButton: true });
  }

  function toggleMobile() {
    setExtendedOption({
      ...extendedOption,
      mobileOpen: !extendedOption.mobileOpen,
    });
  }

  return ReactDOM.createPortal(
    <section className="search-box extended">
      {searchState.qualities.doneLoading && (
        <>
          <div className="field company">
            <label>Opdracht of bedrijfsnaam</label>
            <div className="autocomplete-container input">
              <Autocomplete
                placeholder="Zoek op opdracht of bedrijf"
                filterID="company"
                suggestions={searchState.suggestions.data.companies}
                storeInput={storeInput}
                defaultValueForInput={
                  searchState.input.data.company.searchCategory === "quality"
                    ? ""
                    : searchState.input.data.company.Name
                }
                useGeo={false}
                tooltipIcon=""
              />
            </div>
          </div>
          <div className="field location">
            <label>Locatie</label>
            <div className="autocomplete-container input">
              <Autocomplete
                placeholder="Vul uw plaats of postcode in"
                filterID="place"
                suggestions={searchState.suggestions.data.places}
                storeInput={storeInput}
                defaultValueForInput={searchState.input.data.place}
                useGeo={true}
                tooltipIcon="Bepaal mijn coördinaten"
              />
            </div>
          </div>
          {checkForZipcode() && (
            <div className="field radius">
              <select
                name="radius"
                className="listbox"
                onChange={(e) => selectDistance(e)}
              >
                <option
                  selected={searchState.input.data.distance === 5}
                  value="5"
                >
                  Tot 5 km
                </option>
                <option
                  selected={searchState.input.data.distance === 10}
                  value="10"
                >
                  Tot 10 km
                </option>
                <option
                  selected={searchState.input.data.distance === 20}
                  value="20"
                >
                  Tot 20 km
                </option>
                <option
                  selected={searchState.input.data.distance === 50}
                  value="50"
                >
                  Tot 50 km
                </option>
              </select>
            </div>
          )}
          <div className="field recognition">
            <label>Certificaten</label>
            <div className="autocomplete-container input">
              <select
                onChange={(e) => selectKwaliteitsregeling(e)}
                value={
                  searchState.input.data.quality
                    ? searchState.input.data.quality.Id
                    : 0
                }
              >
                <option value="0">Selecteer kwaliteitsregeling</option>
                {extendedOption.qualitySchemesUsed.map((regeling, index) => (
                  <option key={index} value={regeling.Id}>
                    {regeling.Name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <section
            className={
              extendedOption.mobileOpen
                ? "is-active mobile-search"
                : "mobile-search"
            }
          >
            <h3 onClick={(e) => toggleMobile()}>Zoekresultaten filteren</h3>
            {extendedOption.mobileOpen && (
              <>
                <div className="field location">
                  <label>Locatie</label>
                  <div className="autocomplete-container input">
                    <Autocomplete
                      placeholder="Vul uw plaats of postcode in"
                      filterID="place"
                      suggestions={searchState.suggestions.data.places}
                      storeInput={storeInput}
                      defaultValueForInput={searchState.input.data.place}
                      useGeo={true}
                      tooltipIcon="Bepaal mijn coördinaten"
                    />
                  </div>
                </div>
                {checkForZipcode() && (
                  <div className="field radius">
                    <select
                      name="radius"
                      className="listbox"
                      onChange={(e) => selectDistance(e)}
                    >
                      <option
                        selected={searchState.input.data.distance === 5}
                        value="5"
                      >
                        Tot 5 km
                      </option>
                      <option
                        selected={searchState.input.data.distance === 10}
                        value="10"
                      >
                        Tot 10 km
                      </option>
                      <option
                        selected={searchState.input.data.distance === 20}
                        value="20"
                      >
                        Tot 20 km
                      </option>
                      <option
                        selected={searchState.input.data.distance === 50}
                        value="50"
                      >
                        Tot 50 km
                      </option>
                    </select>
                  </div>
                )}
                <div className="field recognition">
                  <label>Certificaten</label>
                  <div className="autocomplete-container input">
                    <select
                      onChange={(e) => selectKwaliteitsregeling(e)}
                      value={
                        searchState.input.data.quality
                          ? searchState.input.data.quality.Id
                          : 0
                      }
                    >
                      <option value="0">Selecteer kwaliteitsregeling</option>
                      {extendedOption.qualitySchemesUsed.map(
                        (regeling, index) => (
                          <option key={index} value={regeling.Id}>
                            {regeling.Name}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </>
            )}
          </section>
        </>
      )}
      {extendedOption.searchButton && (
        <a className="btn" onClick={activateSearch}>
          Vind een vakman
        </a>
      )}
      {!extendedOption.searchButton && (
        <a className="btn disabled">
          Vind een vakman
        </a>
      )}
    </section>,
    extendedSearchRoot
  );
};

export { ExtendedSearch };
